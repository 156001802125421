import React, { useRef, useEffect } from "react";
import "./index.css";
import CloudPdfViewer from "@cloudpdf/viewer";
export default function App() {
  const viewer = useRef(null);
  useEffect(() => {
    CloudPdfViewer(
      {
        documentId: "83dca2ce-f2bd-44c6-8b2d-37de1d6c8beb",
        darkMode: false
      },
      viewer.current
    ).then((instance) => {});
  }, []);
  return (
    <div className="app">
      <div className="viewer" ref={viewer}></div>
    </div>
  );
}